.search {
    background-color: #212030;
    padding: 5px;
    border-radius: 20px;
}

.search .input-group-text {
    color: var(--title-text-color);
    background-color: transparent;
    border-color: var(--title-text-color);
    border: 0;
}

.search #basic-url {
    color: var(--title-text-color);
    background-color: transparent;
    border: 0;
    padding: 5px;
}

.search #basic-url:focus {
    box-shadow: none;
}

.search #basic-url::placeholder{
    color: var(--text-color);
}