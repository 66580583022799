.skills {
    padding: 0px;
    display: inline;
    flex-wrap: wrap;
    justify-content: space-around;
    cursor: default;
}

.skills li {
    list-style: none;
    display: inline-block;
    color: var(--title-text-color);
    background-color: var(--secondary-color);
    border-radius: 10px;
    padding: 7px;
    margin: 3px;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, font-weight 0.2s ease-in-out;
}

.skills li:hover {
    color: var(--background-color);
    background-color: var(--title-text-color);
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, font-weight 0.2s ease-in-out;
    font-weight: 600;
}

.skill-level {
margin-bottom: 30px;
}

.skill-level .title {
    text-align: left;
    width: 300px;
}