@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

:root {
  --primary-color: #0d6efd;
  --secondary-color: #5a64f3;
  --title-text-color: #fefdff;
  --text-color: #c0becc;
  --background-inner-color: #030218;
  --background-color: #01010e;
  --header-height: 80px;
  --double-header-height: calc(1.5 * var(--header-height));
}

* {
  margin: 0;
  padding: 0;
  font-family: "Quicksand";
}

body {
  background: radial-gradient(circle, var(--background-inner-color), var(--background-color));
  color: var(--text-color);
  min-height: 100vh;
}

h1,
h2,
h3,
.container.content h1,
.container.content h2,
.container.content h3,
.content h1,
.content h2,
.content h3 {
  color: var(--title-text-color);
}

h1,
.container.content h1,
.content h1 {
  text-transform: uppercase;
  font-weight: bold;
}

p,
.container.content {
  color: var(--text-color);
}

.header {
  width: 100%;
  height: var(--header-height);
}

.content {
  min-height: 100vh;
  width: 100%;
  height: 100%;
  margin-top: var(--header-height);
  margin-bottom: var(--header-height);

  @media screen and (max-width: 767px) {
    margin-top: var(--double-header-height);
  }
}

.content object {
  width: 100%;
  height: 100%;
  display: block;
  margin: 0;
  padding: 0;
}

.btn-primary,
.btn-custom {
  color: var(--title-text-color) !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.btn-primary:hover,
.btn-custom:hover {
  color: var(--primary-color) !important;
  background-color: var(--title-text-color) !important;
  border-color: var(--title-text-color) !important;
}

.btn-outline-primary {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-outline-primary:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.text-justify {
  text-align: justify;
}

li {
  color: var(--text-color);
}

.text-link {
  color: var(--secondary-color);
  font-weight: bold;
  transition: color 0.1s ease-in-out;
  cursor: pointer;
}

.text-link:hover {
  color: var(--title-text-color);
}

.text-primary{
  color: var(--primary-color)
}