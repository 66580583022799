:root {
    --project-background-color: #14141d;
    --project-date-color: #a8a8a8;
}

.project {
    background-color: var(--project-background-color);
    text-decoration: none;
    overflow: hidden;
    transition: transform 0.1s ease-in-out;
}

.project .card-img-top {
    border-radius: 22px;
}

.project:hover {
    transform: scale(1.05);
}

.project .card-title {
    font-weight: bold;
}

.project .date {
    color: var(--project-date-color);
}

.project .description {
    margin-top: 5px;
    color: var(--text-color);
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: height 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.project:focus .description,
.project:hover .description {
    opacity: 1;
    height: 100px;
}

.project .skills li:hover {
    color: var(--title-text-color);
    background-color: var(--background-color);
}

.project .store-icon {
    color: var(--text-color);
    font-size: 25px;
    margin-right: 20px;
    transition: color 0.2s ease-in-out;
}

.project .line {
    margin: 0;
    border-width: 3px;
    opacity: 100%;
    color: var(--primary-color);
}