:root {
    --footer-background: #141129;
}

.footer {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: var(--footer-background);
}

.footer .text-link {
    display: flex;
    gap: 10px;
}

.footer .text-link button,
.footer .text-link a {
    font-weight: normal;
    text-decoration: none;
    padding-right: 8px;
    border: none;
    cursor: pointer;
    color: var(--text-color);
    background-color: transparent;
    transition: color 0.1s ease-in-out, font-weight 0.1s ease-in-out;
}

.footer .text-link button:hover,
.footer .text-link a:hover {
    color: var(--primary-color);
}

.footer .text-link button.active,
.footer .text-link a.active {
    color: var(--title-text-color);
    font-weight: bold;
}