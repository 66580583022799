.video-container {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.video {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
}

.video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}