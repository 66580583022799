.project-carousel .carousel-item img {
    width: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 768px) {
    .project-carousel .carousel-item img {
        max-height: 90vh;
    }
}

@media (min-width: 769px) {
    .project-carousel .carousel-item img {
        max-height: 60vh;
    }
}