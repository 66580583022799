:root {
    --navbar-border-radius: 20px;
    --navbar-background-color: rgba(2, 1, 17, 0.5);
    --navbar-border-size: 2px;
}

header .navbar {
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    background-color: var(--navbar-background-color);
}

header .navbar a {
    color: var(--text-color);
}

header .navbar-nav .nav-link {
    transition: color 0.3s ease, background-color 0.3s ease;
    font-weight: 600;
    border-bottom: var(--navbar-border-size) solid var(--title-text-color);
    border-top: var(--navbar-border-size) solid var(--title-text-color);
}

header .navbar .btn-nav {
    color: var(--text-color);
    border: var(--navbar-border-size) solid var(--text-color);
    background-color: transparent;
    transition: color 0.3s ease, border 0.3s ease, background-color 0.3s ease;
}

header .navbar .navbar-toggler:focus,
header .navbar .navbar-toggler {
    color: var(--background-inner-color);
    background-color: var(--title-text-color);
    border: none;
    box-shadow: none;
}

header .navbar .navbar-toggler:focus .toggler-icon,
header .navbar .navbar-toggler .toggler-icon {
    color: inherit;
}

header .navbar .navbar-toggler.collapsed {
    border: none;
    box-shadow: none;
    color: var(--title-text-color);
    background-color: transparent;
}

header .navbar .toggler-icon {
    color: var(--title-text-color);
}

header .navbar .btn-nav:hover {
    color: var(--background-color);
    border: var(--navbar-border-size) solid var(--text-color);
    background-color: var(--title-text-color);
}

header .nav-item a:hover,
header .navbar-nav .nav-link.active {
    color: var(--background-color);
}

@media (max-width: 767px) {
    header .nav-item a {
        padding-left: 20px;
    }
}

@media (min-width: 768px) {
    header .nav-item a {
        padding-left: 0;
    }
}

header .nav-item a:hover {
    background-color: rgba(254, 253, 255, 0.7);
}

header .navbar-nav .nav-link.active {
    background-color: var(--title-text-color);
}

header .nav-item .first-item {
    border-radius: var(--navbar-border-radius) var(--navbar-border-radius) 0 0;
    border-left: var(--navbar-border-size) solid var(--title-text-color);
}

header .nav-item .last-item {
    border-radius: 0 0 var(--navbar-border-radius) var(--navbar-border-radius);
    border-right: var(--navbar-border-size) solid var(--title-text-color);
}

@media (min-width: 768px) {
    header .nav-item .first-item {
        border-radius: var(--navbar-border-radius) 0 0 var(--navbar-border-radius);
        border-left: var(--navbar-border-size) solid var(--title-text-color);
    }

    header .nav-item .last-item {
        border-radius: 0 var(--navbar-border-radius) var(--navbar-border-radius) 0;
        border-right: var(--navbar-border-size) solid var(--title-text-color);
    }
}

header .navbar .navbar-brand {
    padding-right: 40px;
    color: var(--title-text-color);
}

header .navbar .navbar-brand:focus {
    color: var(--title-text-color) !important;
}

header .navbar .navbar-brand:hover {
    color: var(--primary-color);
}

header .nav-icon {
    font-size: 20px;
}