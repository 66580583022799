@keyframes gradientAnimation {
    0% {
        background-position: 100% 0;
    }
    100% {
        background-position: -100% 0;
    }
}

.project .skeleton-img {
    width: 100%;
    height: 250px;
    border-radius: 3px;
    box-sizing: border-box;
    background: linear-gradient(to right, #333, #444, #333); /* Cores escuras para o dark mode */
    background-size: 200% 100%;
    animation: gradientAnimation 2s linear infinite; /* Ajuste a duração conforme necessário */
}
